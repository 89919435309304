import React  from 'react';
import { useStyles } from '../common/styles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  NumberField,
  ReferenceManyField,
  ReferenceField,
  DateField,
  Labeled,
  FunctionField,
} from 'react-admin';
import { find, groupBy } from 'lodash/fp';

import { ShowActions } from '../common';
import { OrderTypeField } from './CustomComponents';
import styles from './orderShow.module.css';

const OrderTitle = ({ record }) => (
  <span>RUNR - View Order {record ? `"${record.shopify_order_id}"` : ''}</span>
);

const ShopifyLink = ({ record, ...props }) => (
  <Labeled label="Shopify Order #">
    <a href={record.order_data.order_status_url} target="_blank" rel="noopener noreferrer">
      {record.shopify_order_no}
    </a>
  </Labeled>
);

const Products = ({ record, classes, ...props }) =>
  record.type?.toLowerCase() === 'regular' ? (
    <Labeled label="Products">
      <ArrayField source="items" record={record} {...props}>
        <Datagrid record={record} {...props}>
          <TextField
            source="title"
            record={record}
            label="Title"
            className={classes.field}
            {...props}
          />
          <NumberField
            source="price"
            record={record}
            label="Price"
            className={classes.field}
            {...props}
          />
          <NumberField
            source="quantity"
            record={record}
            label="Quantity"
            className={classes.field}
            {...props}
          />
          <ReferenceField
            label="Store"
            record={record}
            source="supplier_store_id"
            reference="supplier_stores"
            {...props}
          >
            <TextField source="name" record={record} {...props} />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </Labeled>
  ) : null;

const CourierFields = ({ record, ...props }) => {
  if (record.type?.toLowerCase() === 'regular') {
    return null;
  } else {
    return (
      <>
        <h3>Return order specific fields</h3>
        <Labeled label="Pick Up Address">
          <span>{find({ name: 'pickupAddress' }, record.order_data.note_attributes)?.value}</span>
        </Labeled>
        <br />
        <Labeled label="Pick Up Instructions">
          <span>{record.pickup_instructions}</span>
        </Labeled>
        <br />
        <Labeled label="Purchaser Account Name">
          <span>{record.purchaser_account_name}</span>
        </Labeled>
        <br />
        <Labeled label="Purchaser Company Name">
          <span>{record.purchaser_company_name}</span>
        </Labeled>
        <br />
        <Labeled label="Purchaser Notification Email">
          <span>{record.purchaser_notification_email}</span>
        </Labeled>
      </>
    );
  }
};

const PurchasePhotos = ({ record, ...props }) => {
  const orderType = record.type?.toLowerCase();
  const dropOffPhotos =
    orderType === 'regular' ? record.purchase_pictures_urls : record.drop_off_pictures_urls;
  const pickupPhotos = orderType === 'regular' ? null : record.purchase_pictures_urls;

  return (
    <>
      <h3>Purchase Photos</h3>
      {pickupPhotos && (
        <>
          <h4>Pick Up Photos</h4>
          {pickupPhotos.map((src, idx) => (
            <div key={idx}>
              {!!src && <img src={src} alt="Purchase Photos - Pick up Photos" />}
            </div>
          ))}
        </>
      )}
      {dropOffPhotos && (
        <>
          <h4>Drop Off Photos</h4>
          {dropOffPhotos.map((src, idx) => (
            <div key={idx}>
              {!!src && <img src={src} alt="Purchase Photos - Drop off Photos" />}
            </div>
          ))}
        </>
      )}
    </>
  );
};

const DriverPhotosInner = ({ data, stopPointSlugsOrder, ...props }) => {
  if (!data) {
    return null;
  }
  const photos = Object.values(data);
  const photosOrdered = [
    ...(stopPointSlugsOrder
      // If stopPointSlugsOrder is not null it means the order type is regular
      ? stopPointSlugsOrder.map((stopPointSlug, idx) => ({
          name: `Stop Point ${idx} (${stopPointSlug})`,
          photos: groupBy(
            'description',
            photos.filter(photo => photo.stop_point_slug === stopPointSlug),
          ),
        }))
      : [
          {
            name: 'Pick Up',
            photos: groupBy('description', photos.filter(photo => photo.type === 'pick_up')),
          },
        ]),
    {
      name: 'Drop Off',
      photos: groupBy('description', photos.filter(photo => photo.type === 'drop_off')),
    },
  ];

  return photosOrdered.map((locationPhotos, idx) => (
    <React.Fragment key={idx}>
      <h4>{locationPhotos.name}</h4>
      {Object.keys(locationPhotos.photos)
        .sort()
        .map((description, idx) => (
          <React.Fragment key={idx}>
            <h5>{description}</h5>
            {locationPhotos.photos[description].map((photo, idx) => (
              <div key={idx}>
                {!!photo.photo_url && <img src={photo.photo_url} alt={`Driver Photos - ${description}`} />}
              </div>
            ))}
          </React.Fragment>
        ))}
    </React.Fragment>
  ));
};

const DriverPhotos = ({ record, ...props }) => {
  const stopPointSlugsOrder =
    record.type?.toLowerCase() === 'regular'
      ? record.locations?.stop_points?.map(location => location.slug)
      : null;

  return (
    <>
      <h3>Driver Photos</h3>
      <ReferenceManyField
        label="Photos"
        reference="order_photos"
        target="order_id"
        record={record}
        {...props}
      >
        <DriverPhotosInner stopPointSlugsOrder={stopPointSlugsOrder} />
      </ReferenceManyField>
    </>
  );
};

const OrderShow = props => {
  const classes = useStyles();

  return (
    <Show title={<OrderTitle />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="status" className={classes.field} />

        <Labeled label="Order Type">
          <OrderTypeField />
        </Labeled>

        <ShopifyLink />

        <ReferenceField label="Driver" source="user_id" reference="drivers">
          <TextField source="first_name" />
        </ReferenceField>

        <FunctionField
          label="Client name"
          render={record =>
            `${record.order_data.customer.first_name} ${record.order_data.customer.last_name}`
          }
        />

        <DateField
          source="order_data.created_at"
          label="Order time"
          showTime
          className={classes.field}
        />
        <TextField source="delivery_eta" label="Delivery ETA" className={classes.field} />
        <DateField source="delivered_at" label="Delivery Time" showTime className={classes.field} />

        <FunctionField
          label="Shipping Address"
          render={r =>
            `${r.order_data.shipping_address.address1}, ${r.order_data.shipping_address.city}, ${r.order_data.shipping_address.province}`
          }
        />

        <TextField source="purchase_order_number" label="PO Number" className={classes.field} />
        <TextField source="notes" label="Delivery Instructions" className={classes.field} />

        <TextField
          source="order_data.subtotal_price"
          label="Order subtotal"
          className={classes.field}
        />
        <TextField source="order_data.total_tax" label="Total Tax" className={classes.field} />
        <TextField source="delivery_fee" label="Delivery fee" className={classes.field} />
        <TextField source="driver_fee" label="Driver earnings" className={classes.field} />

        <TextField source="order_data.total_price" label="Total price" className={classes.field} />

        <Products classes={classes} />

        <CourierFields />

        <PurchasePhotos />

        <DriverPhotos />
      </SimpleShowLayout>
    </Show>
  );
};

export { OrderShow };
